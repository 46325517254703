import { Fragment, type FC } from "react";
import { Link } from "react-router-dom";
import classes from './LandlordCard.module.scss';

import Acronim from "../acronim/Acronim";
import Infocard from "../infocard/Infocard";

import { faPhone, faLink } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faBuilding } from "@fortawesome/free-regular-svg-icons";

import { type EnhancedLandlordProps } from "types/shared";

import classNames from "classnames";

const LandlordCard: FC<{ 
    landlord: EnhancedLandlordProps, 
    displayExtraInfo?: boolean; 
    inline?: boolean;
    size?: string; 
}> = ({ landlord, displayExtraInfo = true, inline = false, size = 'normal' }) => {
    return (
        <div className={ classes.info__interface }>
            <div className={ classNames(classes.info__info, { [classes.info__inline]: inline }, classes[`info__info--${size}`]) }>
                <div className={ classes.info__name }>
                    <Acronim name={ landlord.name } width={ size === 'small' ? 30 : 40 } fontSize={ 14 } color="rgb(6, 136, 238)" />
                    <div className={ classes.info__extra }>
                        <Link to={ `/dashboard/landlords/list/${landlord._id}` } target="_blank"> { landlord.name } </Link>
                    </div>
                </div>
                { size === 'small' ? 
                    landlord.emailAddresses
                        .filter((landlord: { email: string }, index) => index < 1)
                        .map((landlord: { email: string }, index: number) => (
                            <Infocard key={ index } label={ `Email ${index + 1}:` } value={ landlord.email } icon={ faEnvelope } />
                        )) 
                    : landlord.emailAddresses
                        .map((landlord: { email: string }, index: number) => (
                            <Infocard key={ index } label={ `Email ${index + 1}:` } value={ landlord.email } icon={ faEnvelope } />
                        )) 
                }
                { size === 'small' ?
                    landlord.phoneNumbers
                        .filter((landlord: { phone: string }, index) => index < 1)
                        .map((landlord: { phone: string }, index: number) => (
                            <Infocard key={ index } label={ `Phone ${index + 1}:` } value={ landlord.phone } icon={ faPhone } />
                        ))
                        : landlord.phoneNumbers.map((landlord: { phone: string }, index: number) => (
                            <Infocard key={ index } label={ `Phone ${index + 1}:` } value={ landlord.phone } icon={ faPhone } />
                        )) 
                 }
                { displayExtraInfo && (
                    <Fragment>
                        { landlord.company.name && (
                            <Infocard label="Company Name" value={ landlord.company.name } icon={ faBuilding } />
                        ) }
                        { landlord.company.url && (
                            <Infocard label="Company Url" value={ landlord.company.url } icon={ faLink } />
                        ) }     
                    </Fragment>
                ) }
            </div>
        </div>
    )
}

export default LandlordCard;