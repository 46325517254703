import { Suspense, useState, useEffect} from "react";
import { currentModal, CurrentModal } from "./currentModal";

import classes from './ModalRenderer.module.scss';

import { CloseButton } from "components/shared";

import { motion } from "framer-motion"

export const ModalRenderer = () => {
  const [modal, updateCurrentModal] = useState<CurrentModal<any> | null>(null);
  
  useEffect(() => currentModal.subscribe(updateCurrentModal), []);
  
  if (modal) {
    const Modal = currentModal.get(modal.name);

    return (
      <Suspense>
          <div className={ classes.modal }>
            <motion.div
              transition={{ duration: 0.2 }}
              initial={{ opacity: 0, position: 'absolute', width: '100%', top: '50%', transform: 'translateY(-50%) scale(0.9)' }}
              whileInView={{ opacity: 1, position: 'absolute', width: '100%', top: '50%', transform: 'translateY(-50%) scale(1)' }}
            >
              <div className={ classes.modal__container }>
                <CloseButton style={{ position: 'sticky', right: '0', top: '0', marginLeft: 'auto' }} onClick={ () => { document.body.removeAttribute("style"); currentModal.set(null); } } />
                <Modal {...modal?.props} />
              </div>
            </motion.div>
          </div>
      </Suspense>
    )
  }
  
  return null;
}