import { type FC } from "react";
import classes from './Infocard.module.scss';

import { type IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

const Infocard: FC<{
    label: string,
    value?: string,
    array?: string[],
    icon: IconDefinition,
    inline?: boolean;
}> = ({ label, value, array, icon, inline = false }) => {
    return (
        <div className={ classNames(classes.infocard, {[classes['infocard--inline']]: inline}) }> 
            <p className={ classes.infocard__label }><FontAwesomeIcon icon={ icon } /> { label }{inline ? ':' : ''}</p>
            { array ? (
                <div className={ classes.infocard__items }>
                    { array.map((item: string, index: number) => (
                        <p className={ classes.infocard__tag } key={ index }> { item } </p>
                    )) }
                </div>
            ) : (
                <p className={ classes.infocard__value }> { value } </p>
            ) }
        </div>
    )
}

export default Infocard;