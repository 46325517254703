import { 
    useQuery
} from "@tanstack/react-query";

import { fetchCurrentAccount } from "service/account.service";
import { fetchUsers } from "service/user.service";
import { fetchCompanies } from "service/company.service";

const CurrentUserApi = {
    FetchById: {
        useQuery: (
            runningCondition: boolean
        ) =>
            useQuery({
                queryKey: ['currentUserProvider'],
                queryFn: async () => {
                    const response = await Promise.allSettled([
                        fetchCurrentAccount(),
                        fetchUsers(),
                        fetchCompanies()
                    ]);

                    if (
                        response && response[0].status === "fulfilled" &&
                        response && response[1].status === "fulfilled" && 
                        response && response[2].status === "fulfilled"
                    ) {
                        return {
                            userData: response[0].value.data.userData,
                            users: response[1].value.data.users,
                            companies: response[2].value.data.companies
                        }
                    }
                },
                enabled: runningCondition
            })
    }
}

export default CurrentUserApi;