import { type FC } from "react";
import { useNavigate } from "react-router-dom";
import classes from './CreatorInfo.module.scss';

import Acronim from "../acronim/Acronim";
import Image from "../image/Image";

import { hasProfilePicture } from "utils";

import { type EnhancedSubject } from "types/shared";

const CreatorInfo: FC<{
    subject: EnhancedSubject,
    displayName?: boolean;
    urlPath?: string;
}> = ({ subject, displayName = true, urlPath = 'accounts' }) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        const path = window.location.pathname;
        
        navigate(`/dashboard/${urlPath}/${subject._id}`, { state: { path } });
    }

    return (
        <div className={ classes.creator }>
            { !displayName && (
                <span style={{ width: `${subject.fullName.length * 8}px` }} className={ classes.creator__tooltip }> { subject.fullName } </span>
            ) }
            <button onClick={ handleNavigate }>
                { hasProfilePicture(subject) ? (
                    <Image className={ classes.creator__image } source={ subject.imageUrl.key } alt={ subject.imageUrl.name } />
                ) : (
                    <Acronim fontSize={ 14 } width={ 30 } name={ subject.fullName } />
                ) }
                { displayName && <p className={ classes.creator__name }> { subject.fullName } </p> }
            </button>
        </div>
    )
}

export default CreatorInfo;