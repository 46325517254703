import React, { type ReactNode } from 'react';

import type { CompanyProps, Subject } from 'types/shared';

import { useAuthContext } from './AuthContext';
import { Loader } from 'components/shared';

import CurrentUserApi from 'api/current-user.api';

type ContextTypes = {
    users: any;
    companies: CompanyProps[];
    currentUser: Subject | null;
}

type CurrentUserContextTypes = {
    value?: ContextTypes;
    children: ReactNode;
}

export const CurrentUserContext = React.createContext<ContextTypes>({
    users: null,
    companies: [],
    currentUser: null
});

export const CurrentUserProvider = (props: CurrentUserContextTypes) => {
    const { token } = useAuthContext();

    const { data, isLoading, isFetching } = CurrentUserApi.FetchById.useQuery(Boolean(token));


    const allDataLoaded = () => {
        return (
            data?.userData &&
            data.users &&
            data.companies
        ) ? true : false;
    }

    const value = { 
        users: data?.users,
        companies: data?.companies,
        currentUser: data?.userData
    };

    if ([isLoading, isFetching].some(Boolean)) {
        return <Loader />
    }

    return (
        <CurrentUserContext.Provider value={ value }>
            { allDataLoaded() ? props.children : null }
        </CurrentUserContext.Provider>
    )
}

export const useCurrentUser = () => React.useContext(CurrentUserContext);